<template>
  <common-form
      ref="form"
      url="web/thirdpart/shenzhen"
      :edit="edit"
      :items="items"
      @success="$emit('success')"></common-form>
</template>
<script>

export default {
  data() {
    return {
      edit: false,
    }
  },
  computed: {
    items() {
      return [
        {
          type: "input",
          name: "名称",
          key: "name",
          required: true,
        },
        {
          type: "input",
          name: "设备MAC",
          key: "mac",
          required: true,
        },
        {
          type: "input",
          name: "设备Token",
          key: "appKey",
          required: true,
        },

      ]
    }
  },
  methods: {
    show(model = {}) {
      this.edit = model.id > 0
      this.$refs.form.show(model)
    },
  }

}
</script>
